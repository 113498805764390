import { extend, localize } from "vee-validate";
import {
    required as rule_required,
    doubel as rule_double,
    email as rule_email,
    min as rule_min,
    confirmed as rule_confirmed,
    regex as rule_regex,
    between as rule_between,
    alpha as rule_alpha,
    integer as rule_integer,
    digits as rule_digits,
    alpha_dash as rule_alpha_dash,
    alpha_num as rule_alpha_num,
    length as rule_length,
} from "vee-validate/dist/rules";

// eslint-disable-next-line object-curly-newline
import {
    validatorPositive,
    validatorUrlValidator,
    validatorPassword,
    validatorCreditCard,
    validatorDate,
    validatorTime,
    validatorWeek,
    validatorInternationalPhonePrefix,
    validatorElevenProof,
    validatorSofiNumber,
    validatorBTWNumber,
    validatorDocument,
    validatorAge,
    validatorDateMore,
    validatorNumberMore,
    validatorDateMoreThanToday,
    validatorCorrectPrice,
    validatorCorrectPriceConversionFactor,
    validatorCorrectNumber,
    validatorDateOneMonthPast,
    validatorMultipleEmails,
    validatorMinValue,
    validatorDisableDateFuture,
    validatorDisableDatePast,
    validatorDisableTime
} from "./validators";

// ////////////////////////////////////////////////////////
// General
// ////////////////////////////////////////////////////////

export const required = extend("required", rule_required);

export const email = extend("email", rule_email);

export const min = extend("min", rule_min);

export const confirmed = extend("confirmed", rule_confirmed);

export const regex = extend("regex", rule_regex);

export const between = extend("between", rule_between);

export const alpha = extend("alpha", rule_alpha);

export const integer = extend("integer", rule_integer);

export const digits = extend("digits", rule_digits);

export const alphaDash = extend("alpha-dash", rule_alpha_dash);

export const alphaNum = extend("alpha-num", rule_alpha_num);

export const length = extend("length", rule_length);

export const positive = extend("positive", {
    validate: validatorPositive,
    message: "Please enter positive number!",
});

export const credit = extend("credit-card", {
    validate: validatorCreditCard,
    message: "It is not valid credit card!",
});

export const password = extend("password", {
    validate: validatorPassword,
    message: "Your {_field_} must contain at least one uppercase, one lowercase, one special character and one digit",
});

export const url = extend("url", {
    validate: validatorUrlValidator,
    message: "URL is invalid",
});

export const date = extend("date-check", {
    validate: validatorDate,
    message: "Datum is ongeldig",
});

export const time = extend("timecheck", {
    validate: validatorTime,
    message: "Tijd is ongeldig",
});

export const week = extend("weekcheck", {
    validate: validatorWeek,
    message: "Uren per week is ongeldig",
});

export const age = extend("age-check", {
    validate: validatorAge,
    message: "Voor registratie bij HappyNurse dien je minimal 16 jaar oud te zijn",
});

export const dateMoreThanToday = extend("date-more-check", {
    validate: validatorDateMoreThanToday,
    message: "De ingevoerde datum moet hoger zijn dan de datum van vandaag",
});

export const documentDate = extend("document-date", {
    validate: validatorDocument,
    message: "Verloopdatum mag niet in de toekomst liggen",
});

export const documentDateOneMonthPast = extend("document-date-month-past", {
    validate: validatorDateOneMonthPast,
    message: "Verloopdatum mag niet in de toekomst liggen",
});

export const phoneprefix = extend("phone-prefix", {
    validate: validatorInternationalPhonePrefix,
    message: "Nummer is ongeldig",
});

export const elevenproof = extend("eleven-proof", {
    validate: validatorElevenProof,
    message: "IBAN is ongeldig",
});

export const correctPrice = extend("correct-Price", {
    validate: validatorCorrectPrice,
    message: "Incorrecte number",
});

export const correctPriceConversionFactor = extend("correct-price-conversion-factor", {
    validate: validatorCorrectPriceConversionFactor,
    message: "Incorrecte number",
});

export const correctNumber = extend("correct-Number", {
    validate: validatorCorrectNumber,
    message: "Incorrecte number",
});

export const sofinumber = extend("sofi-number", {
    validate: validatorSofiNumber,
    message: "BSN nummer is ongeldig",
});

export const btwnumber = extend("btw-number", {
    validate: validatorBTWNumber,
    message: "BTW nummer is ongeldig",
});

export const multipleEmails = extend("multiple-emails", {
    validate: validatorMultipleEmails,
    message: "Email moet een geldig e-mailadres zijn",
});


const paramNames = ['dateTo'];

export const dateMoreThan = extend("data-more-than", {
    validate: validatorDateMore,
    message: "Incorrecte datum",
},{
    paramNames
});

export const numberMoreThan = extend("number-more-than", {
    validate: validatorNumberMore,
    message: "Incorrecte week",
},{
});


export const decimal_min_value = extend("app-min-value", {
    validate: validatorMinValue,
    message: (field, args) => "De waarde moet minimaal " + args[0]
});


export const date_block_past = extend("disable-date-past", {
    validate: validatorDisableDatePast,
    message: "Einddatum mag niet in het verleden liggen"
});

export const date_block = extend("disable-date-future", {
    validate: validatorDisableDateFuture,
    message: (field, args) => (args[0] == 365 ? "Einddatum mag niet meer dan een jaar in de toekomst liggen" : "Je kunt geen geblokkeerde datum selecteren")
});

export const time_block = extend("disable-time", {
    validate: validatorDisableTime,
    message: "Een uur geblokkeerd"
});


